<template>
    <div class="mx-5 sm:mx-16 md:mx-20 text-white sm:text-lg grid grid-cols-1 justify-items-center text-center gap-5 sm:gap-7">
        <img 
            src="../assets/images/about/about.jpg"
            class="rounded-lg shadow-xl mt-6 sm:mt-8 h-auto w-96"
        />
        <p>Redefining modern spaces with bespoke designs.</p>
        <p class="sm:-my-7">We create interior spaces that reflect art and luxury.</p>
        <p class="-my-1">The atelier produces a variety of furniture and accessories carefully designed with the best materials and high end finishing.</p>
        <p> Our services are synonymous with experience, talent, creativity and innovation that reflects the art and science of interior design.</p>
        <img 
            src="../assets/images/about/about-image.jpg"
            class="rounded-lg shadow-xl h-auto lg:w-2/3"
        />
    </div>
</template>